@mixin btn_mute(){
    width: 17px;
    height: 17px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-212px -205px;
}
@mixin btn_next(){
    width: 19px;
    height: 34px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-212px 0px;
}
@mixin btn_pause(){
    width: 8px;
    height: 13px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -141px;
}
@mixin btn_play(){
    width: 11px;
    height: 15px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -74px;
}
@mixin btn_prev(){
    width: 19px;
    height: 34px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-212px -39px;
}
@mixin btn_sound(){
    width: 17px;
    height: 13px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px -234px;
}
@mixin btn_sound_min(){
    width: 17px;
    height: 13px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-22px -234px;
}
@mixin btn_sub(){
    width: 12px;
    height: 9px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -127px;
}
@mixin iconAlram(){
    width: 26px;
    height: 26px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-179px -128px;
}
@mixin iconApple(){
    width: 28px;
    height: 33px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-179px -47px;
}
@mixin iconArrow(){
    width: 14px;
    height: 11px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -94px;
}
@mixin iconArrow_blue(){
    width: 7px;
    height: 11px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -159px;
}
@mixin iconArrow_down(){
    width: 8px;
    height: 5px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -231px;
}
@mixin iconArrow_gray(){
    width: 7px;
    height: 11px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -175px;
}
@mixin iconArryChk(){
    width: 8px;
    height: 6px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -220px;
}
@mixin iconBigX(){
    width: 33px;
    height: 32px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-45px -132px;
}
@mixin iconBtnPlay(){
    width: 15px;
    height: 15px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-236px -188px;
}
@mixin iconCalendar(){
    width: 19px;
    height: 19px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-212px -105px;
}
@mixin iconCheck_off(){
    width: 16px;
    height: 16px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-236px -125px;
}
@mixin iconCheck_on(){
    width: 16px;
    height: 16px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-236px -146px;
}
@mixin iconCheck_onHelp(){
    width: 18px;
    height: 18px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-212px -153px;
}
@mixin iconChk(){
    width: 18px;
    height: 17px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-189px -205px;
}
@mixin iconChkCancel(){
    width: 50px;
    height: 50px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px -77px;
}
@mixin iconChkList(){
    width: 14px;
    height: 14px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px 0px;
}
@mixin iconChkOk(){
    width: 51px;
    height: 51px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-77px 0px;
}
@mixin iconChkQuz(){
    width: 40px;
    height: 33px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px -132px;
}
@mixin iconChkStar(){
    width: 39px;
    height: 37px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-133px -46px;
}
@mixin iconChkbox(){
    width: 10px;
    height: 7px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -208px;
}
@mixin iconChkinfo(){
    width: 14px;
    height: 14px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-193px -170px;
}
@mixin iconControlWrite(){
    width: 24px;
    height: 24px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-135px -170px;
}
@mixin iconControlZoom(){
    width: 24px;
    height: 24px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-164px -170px;
}
@mixin iconDelete(){
    width: 24px;
    height: 24px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px -205px;
}
@mixin iconDelete_help(){
    width: 24px;
    height: 24px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-29px -205px;
}
@mixin iconDelete_help_on(){
    width: 24px;
    height: 24px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-58px -205px;
}
@mixin iconEmptyX(){
    width: 22px;
    height: 22px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-116px -205px;
}
@mixin iconFirstPage(){
    width: 30px;
    height: 30px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-83px -132px;
}
@mixin iconFlow(){
    width: 24px;
    height: 38px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-179px -85px;
}
@mixin iconLastPage(){
    width: 30px;
    height: 30px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-118px -132px;
}
@mixin iconMaster(){
    width: 13px;
    height: 13px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -38px;
}
@mixin iconMedal(){
    width: 16px;
    height: 18px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-236px -80px;
}
@mixin iconMore(){
    width: 15px;
    height: 15px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-236px -208px;
}
@mixin iconNextPage(){
    width: 30px;
    height: 30px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px -170px;
}
@mixin iconNote01(){
    width: 20px;
    height: 21px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-153px -132px;
}
@mixin iconNote02(){
    width: 15px;
    height: 21px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-236px -25px;
}
@mixin iconNote03(){
    width: 12px;
    height: 24px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-236px -51px;
}
@mixin iconNote04(){
    width: 16px;
    height: 20px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-236px 0px;
}
@mixin iconNote05(){
    width: 17px;
    height: 22px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-212px -78px;
}
@mixin iconNote06(){
    width: 16px;
    height: 17px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-236px -103px;
}
@mixin iconPlay(){
    width: 41px;
    height: 41px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-133px 0px;
}
@mixin iconPlayBig(){
    width: 72px;
    height: 72px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px 0px;
}
@mixin iconPowerOff(){
    width: 43px;
    height: 45px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-55px -77px;
}
@mixin iconPrevPage(){
    width: 30px;
    height: 30px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-35px -170px;
}
@mixin iconReply(){
    width: 18px;
    height: 12px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-44px -234px;
}
@mixin iconRun(){
    width: 15px;
    height: 15px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-77px -56px;
}
@mixin iconSearch(){
    width: 15px;
    height: 15px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-97px -56px;
}
@mixin iconSearchHelp(){
    width: 18px;
    height: 19px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-212px -129px;
}
@mixin iconSmArrow(){
    width: 6px;
    height: 12px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -191px;
}
@mixin iconSmClock(){
    width: 13px;
    height: 13px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -56px;
}
@mixin iconTemp(){
    width: 15px;
    height: 44px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-103px -77px;
}
@mixin iconTimeWh(){
    width: 14px;
    height: 14px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -19px;
}
@mixin iconTree(){
    width: 28px;
    height: 42px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-179px 0px;
}
@mixin iconUnChkStar(){
    width: 39px;
    height: 37px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-133px -88px;
}
@mixin iconUser(){
    width: 26px;
    height: 26px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-104px -170px;
}
@mixin iconX(){
    width: 16px;
    height: 16px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-236px -167px;
}
@mixin iconZoom(){
    width: 18px;
    height: 18px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-212px -176px;
}
@mixin iconZoomGray(){
    width: 11px;
    height: 12px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-257px -110px;
}
@mixin iconZoomResult(){
    width: 18px;
    height: 18px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-143px -205px;
}
@mixin iconZoomResult_over(){
    width: 18px;
    height: 18px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-166px -205px;
}
@mixin imgFileAdd(){
    width: 29px;
    height: 29px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-70px -170px;
}
@mixin imgFileDel(){
    width: 24px;
    height: 24px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-87px -205px;
}

.btn_mute {@include btn_mute();}
.btn_next {@include btn_next();}
.btn_pause {@include btn_pause();}
.btn_play {@include btn_play();}
.btn_prev {@include btn_prev();}
.btn_sound {@include btn_sound();}
.btn_sound_min {@include btn_sound_min();}
.btn_sub {@include btn_sub();}
.iconAlram {@include iconAlram();}
.iconApple {@include iconApple();}
.iconArrow {@include iconArrow();}
.iconArrow_blue {@include iconArrow_blue();}
.iconArrow_down {@include iconArrow_down();}
.iconArrow_gray {@include iconArrow_gray();}
.iconArryChk {@include iconArryChk();}
.iconBigX {@include iconBigX();}
.iconBtnPlay {@include iconBtnPlay();}
.iconCalendar {@include iconCalendar();}
.iconCheck_off {@include iconCheck_off();}
.iconCheck_on {@include iconCheck_on();}
.iconCheck_onHelp {@include iconCheck_onHelp();}
.iconChk {@include iconChk();}
.iconChkCancel {@include iconChkCancel();}
.iconChkList {@include iconChkList();}
.iconChkOk {@include iconChkOk();}
.iconChkQuz {@include iconChkQuz();}
.iconChkStar {@include iconChkStar();}
.iconChkbox {@include iconChkbox();}
.iconChkinfo {@include iconChkinfo();}
.iconControlWrite {@include iconControlWrite();}
.iconControlZoom {@include iconControlZoom();}
.iconDelete {@include iconDelete();}
.iconDelete_help {@include iconDelete_help();}
.iconDelete_help_on {@include iconDelete_help_on();}
.iconEmptyX {@include iconEmptyX();}
.iconFirstPage {@include iconFirstPage();}
.iconFlow {@include iconFlow();}
.iconLastPage {@include iconLastPage();}
.iconMaster {@include iconMaster();}
.iconMedal {@include iconMedal();}
.iconMore {@include iconMore();}
.iconNextPage {@include iconNextPage();}
.iconNote01 {@include iconNote01();}
.iconNote02 {@include iconNote02();}
.iconNote03 {@include iconNote03();}
.iconNote04 {@include iconNote04();}
.iconNote05 {@include iconNote05();}
.iconNote06 {@include iconNote06();}
.iconPlay {@include iconPlay();}
.iconPlayBig {@include iconPlayBig();}
.iconPowerOff {@include iconPowerOff();}
.iconPrevPage {@include iconPrevPage();}
.iconReply {@include iconReply();}
.iconRun {@include iconRun();}
.iconSearch {@include iconSearch();}
.iconSearchHelp {@include iconSearchHelp();}
.iconSmArrow {@include iconSmArrow();}
.iconSmClock {@include iconSmClock();}
.iconTemp {@include iconTemp();}
.iconTimeWh {@include iconTimeWh();}
.iconTree {@include iconTree();}
.iconUnChkStar {@include iconUnChkStar();}
.iconUser {@include iconUser();}
.iconX {@include iconX();}
.iconZoom {@include iconZoom();}
.iconZoomGray {@include iconZoomGray();}
.iconZoomResult {@include iconZoomResult();}
.iconZoomResult_over {@include iconZoomResult_over();}
.imgFileAdd {@include imgFileAdd();}
.imgFileDel {@include imgFileDel();}

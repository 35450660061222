@charset "UTF-8";

//메인컬러
$cBlue: #0f3f6f;

//배경컬러
$bgGray: #f6f6f8;

//라인컬러
$cBrdGray: #e5e5e5;

//텍스트컬러
$cFont:#212121;
$cFontGray:#717171;


$cRed:#dc495b;
$cSkyBlue:#5ca4de;
.cRed{ color: $cRed;}
.cBlue{ color: $cBlue;}
.cSkyBlue{ color: $cSkyBlue;}
.cGreen {color:#48a51b;}
@charset "UTF-8";

.mgT0 {margin-top:0}
.mgT10 {margin-top:10px}
.mgT15 {margin-top:15px}
.mgT20 {margin-top:20px}
.mgT25 {margin-top:25px}
.mgT30 {margin-top:30px;}
.mgT35 {margin-top:35px;}
.mgT40 {margin-top:40px;}
.mgT50 {margin-top:50px}
.mgT60 {margin-top:60px}
.mgT45 {margin-top:45px;}
.mgT80 {margin-top:80px;}

.mgR10 {margin-right:10px !important;}
@charset "UTF-8";
.wrap{
  color: #212121;
}

.main_section{
  margin-bottom: 60px;
  padding: 0 15px 0;
  &.gray_bg{
    padding: 30px 15px;
    background: #fafbfd;
  }
}
.section_info{
  .sec_tit{
    display: inline-block;
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: -.05em;
    vertical-align: middle;
    &.link{
      padding-right: 16px;
      background: url(/images/ico_main_sub_tit_arrow.png) right 50% /6px 11px no-repeat;
      content: '';

    }
  }

  .sub_tit{
    margin-top: 2px;
    font-size: 1.4rem;
    color: #666;
  }
}

header {
  .top_bnr{
    position: relative;
    width: 100%;
    height: 40px;
    padding: 0 30px;
    background: #3ac3e3;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 1.2rem;

    .btn_top_bnr_close{
      position: absolute;
      width: 40px;
      height: 40px;
      top: 0;
      right: 10px;
      background: url(/images/btn_header_bnr_close.png) 50% 50% /11px 11px no-repeat;
    }
  }

  .logo_area{
    height: 45px;
    padding: 15px 15px 0;

    .logo{
      display: inline-block;
      width: 93px;
      height: auto;
      img{
        width: 100%;height:auto;
      }
    }


  }

  .gnb{
    .menu{
      position: relative;
      display: inline-block;
      padding: 17px 15px;
      width: auto !important;
      font-size: 1.4rem;
      font-weight: 500;
      white-space: nowrap;

      &.active{
        font-weight: bold;
        &::after{
          display: inline-block;
          position: absolute;
          width: 4px;
          height: 4px;
          color: #212121;
          background: #e91f27;
          border-radius: 50%;
          content: "";
        }
      }

    }
  }
}


//main slider
.main_slider{
  margin-bottom: 60px;
  .slide_content{
    display: block;

    &:after{
      display: block;
      width: 67.5px;
      height: 9px;
      margin-top: 16px;
      margin-left: 15px;
      background: url(/images/btn_main_slide_more.png) 0 0 /100% 100% no-repeat;
      content:'';
    }

    .img_box{
      width: 100%;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
    }

    .text_box{
      width: 100%;
      padding: 20px 15px 0;
      color: #212121;

      .txt_category{
        font-size: 1.3rem;
        font-weight: bold;
        line-height: 1.58;
      }

      .txt_tit{
        width: 45%;
        font-size: 2rem;
        font-weight: 500;
        @include ellipsis_multi(2,1.38)
      }

      .txt_cont{
        width: 60%;
        margin-top: 5px;
        font-size: 1.2rem;
        @include ellipsis_multi(3,1.5)

      }
    }
  }
  .swiper-pagination{
    display: inline-block;
    position: absolute;
    top: inherit;
    left: inherit;
    bottom: 198px;
    right: 15px;
    width: 48px;
    height: 27px;
    background: rgba(0, 0, 0, .5);
    border-radius: 30px;
    line-height: 27px;
    text-align: center;
    color: #fff;
    letter-spacing: .5px;
  }

}

//list type01
.list_type01{
  font-size: 0;

  .list_cont{
    display: inline-block;
    width: 150px;
    vertical-align: top;
    .thumb{
      width: 150px;
      height: 150px;
      overflow: hidden;
      border: 1px solid rgba(204, 204, 204, .4);

      img{
        width: 100%;
        height: 100%;
      }

    }
    .info{
      padding: 9px 0 0;
      .label_group{
        max-width: 100%;
        .label{
          display: inline-block;
          vertical-align: top;
          padding: 0 5px;
          height: 20px;
          border: 1px solid #555;
          font-size: 10px;
          letter-spacing: -0.25px;
          color: #555;
          line-height: 18px;
          font-weight: 500;
          &+.label{
            margin-left: 5px;
          }
        }
      }

      .pdt_name{
        margin-top: 5px;
        font-size: 1.1rem;
        color: #555;
      }

      .pdt_tit{
        width: 100%;
        height: 30px;
        font-size: 1.2rem;
        @include ellipsis_multi(2,1.25)
      }

      .pdt_price{
        margin-top: 2px;
        font-size: 1.3rem;
        font-weight: bold;
        letter-spacing: -0.4px;
        span{
          margin-left: 2px;
          font-size: 1rem;
          vertical-align: bottom;
        }

      }
    }
  }

  .multi_line{
    width: 150px;
    display: inline-block;
    .list_cont{
      display: block;
      margin-bottom: 30px;
    }
  }
}

//tab style01
.tab_style01{
  display: flex;
  .tablinks{
    position: relative;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.47;
    color: #aaa;
    letter-spacing: -0.38px;

    &+.tablinks{
      margin-left: 24px;
    }

    &.active{
      color: #212121;
      font-weight: bold;
      &::after{
        display: inline-block;
        position: absolute;
        top: 0;
        right: -5px;
        width: 4px;
        height: 4px;
        color: #212121;
        background: #e91f27;
        border-radius: 50%;
        content: "";
      }
    }
  }
}

.tabcontent{
  width: 100%;
  overflow: hidden;
}

//event list
.event_list{
  .list_cont{
    margin-bottom: 15px;
    a{
      display: block;
      font-size: 0;

      .thumbnails{
        display: inline-block;
        vertical-align: middle;
        width: 30%;
        height: auto;
        border: 1px solid rgba(204, 204, 204, .5);
        img{
          width: 100%;
          height: auto;
          max-width: 100%;
        }
      }

      .evt_info{
        display: inline-block;
        vertical-align: middle;
        width: 70%;
        padding: 0 20px 0 15px;
        font-size: 1.4rem;
        .txt{
          width: 100%;
          @include ellipsis_multi(2,2rem)
        }

      }
    }

  }
}

//Chart
.chart_data{
  border: 1px solid #cccccc;
  padding: 25px 15px;
  .ranking_list{
    li{
      &+li{
        margin-top: 30px;
      }
      a{
        display: block;
        font-size: 0;

        .album_art{
          position: relative;
          display: inline-block;
          vertical-align: middle;
          width: 15%;
          border: 1px solid rgba(204, 204, 204, .5);
          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }

          img{
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }

        .list_num{
          display: inline-block;
          vertical-align: middle;
          width: 11%;
          font-size: 1.4rem;
          text-align: center;
        }

        .list_info{
          display: inline-block;
          vertical-align: middle;
          width: calc( 100% - 26%);

          .tit{
            width: 100%;
            @include ellipsis;
            font-size: 1.4rem;
            color: #555;
          }

          .name{
            margin-top: 2px;
            font-size: 1.2rem;
            color: #000;
            font-weight: 500;
          }
        }

      }
    }
  }
}

//Ktown4u Movie
.movie_list{
  li{
    &+li{
      margin-top: 20px;
    }
    a{
      display: block;
      font-size: 0;

      .thumbnails{
        display: inline-block;
        vertical-align: middle;
        position: relative;
        width: 40%;
        border: 1px solid rgba(204, 204, 204, .5);
        img{
          width: 100%;
          height: 100%;
          max-width: 100%;
        }
      }

      .tit{
        display: inline-block;
        vertical-align: middle;
        padding-left: 15px;
        width: calc( 100% - 40% );
        font-size: 1.4rem;
        color: #212121;
        .txt{
          width: 100%;
          @include ellipsis_multi(2,2.1rem)
        }
      }
    }
  }
}

//banner
.banner_area{
    display: block;
    img{
      width: 100%;
      height: auto;
    }
}

//Review
.review_list{
  .review_cont{
    width: 210px;
    display: inline-block;

    .review_img{
      display: block;
      width: 100%;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
    }

    .review_info{
      display: block;
      padding-top: 15px;
      .user{
        font-size: 1.35rem;
        color: #000;
      }

      .count{
        margin-top: 6px;
        img{
          width: 18px;
          vertical-align: top;
        }
      }

      .txt{
        margin-top: 6px;
        font-size: 1.2rem;
        line-height: 1.5;
        color: #555;
        @include ellipsis_multi(3,1.8rem);
        img {
          display: none;
        }
      }
    }
  }
}

//bottom menu
.bottom_menu{
  position: fixed;
  width: 100%;
  z-index: 99999;
  bottom: 0;
  .nav{
    display: flex;
    background: #212121;
    li{
      width: 25%;
      height: 60px;
      text-align: center;
      .btn_m{
        display: inline-block;
        width: 60px;
        height: 60px;
        background-position: 50% 50%;
        background-size: 21px 21px;
        background-repeat: no-repeat;

        &.menu{background-image: url(/images/ico_menu.png)}
        &.cart{background-image: url(/images/ico_cart.png)}
        &.mypage{background-image: url(/images/ico_mypage.png)}
        &.search{background-image: url(/images/ico_search.png)}
        &.alert{background-image: url(/images/ico_alert.png)}
      }
    }

  }

}